// module
import React from 'react';
import PropTypes from 'prop-types';

function DropDownButton(props) {
	return (
		<div className={'dropdown d-sm-inline-block' + (props.open ? ' show' : '')}>
			<button
				className="btn btn-primary" /* dropdown-toggle */
				type="button"
				id="dropdownMenuButton"
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false"
				onClick={props.toggle}
			>
				{props.children}
			</button>
			<div
				className={'dropdown-menu right' + (props.open ? ' show' : '')}
				aria-labelledby="dropdownMenuButton"
			>
				{props.items.map((item, i) =>
					item ? (
						<div key={i} className="dropdown-item">
							{item}
						</div>
					) : null
				)}
			</div>
		</div>
	);
}

DropDownButton.defaultProps = {
	open: false,
};
DropDownButton.propTypes = {
	open: PropTypes.bool,
	toggle: PropTypes.func.isRequired,
	items: PropTypes.arrayOf(PropTypes.node).isRequired,
	children: PropTypes.node.isRequired,
};

export default DropDownButton;
