// module
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import TableLeases from '../../../components/table/TableLeases';
import TableLease from '../../../components/table/TableLease';
import Modal from '../../../components/Modal';
import Loading from '../../../components/Loading';
//action
import {
	setLeases,
	setCurrentLease,
} from '../../../action_creators/leasesActionCreators';
import { setLog } from '../../../action_creators/logActionCreators';
// functions
import { getRequest, responseManagment } from '../../../functions/ajax';

class MyLeases extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			are_loading_leases: false,
			is_loading_lease: false,
			display_lease_modal: false,
		};

		this.responseManagment = responseManagment.bind(this);
		this.getLeaseDetails = this.getLeaseDetails.bind(this);
		this.goBack = this.goBack.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.getLeases();
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async getLeases() {
		if (!Object.keys(this.props.leases.list).length) {
			this.setState({ are_loading_leases: true });
			const response = await getRequest(
				`/leases?activity=${this.props.properties.current.id}`,
				this.props.user.token
			);
			if (this.responseManagment(response)) {
				this.props.setLeases(response.leases);
			}

			if (this._isMounted) {
				this.setState({ are_loading_leases: false });
			}
		}
	}

	async getLeaseDetails(id) {
		this.setState({ is_loading_lease: true });
		this.toggleModal(true);
		const response = await getRequest(
			`/leases/${id}?activity=${this.props.properties.current.id}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setCurrentLease(response.lease);
		}

		if (this._isMounted) {
			this.setState({ is_loading_lease: false });
		}
	}

	toggleModal(is_visible) {
		if (this._isMounted) {
			this.setState({
				display_lease_modal: is_visible,
			});
		}
	}

	goBack(evt) {
		this.props.history.push(`/property/${this.props.match.params.id}/data`);
	}

	render() {
		const leases = Object.values(this.props.leases.list);
		return (
			<div className="grid grid-1 my-leases">
				<div className="card">
					<div className="card-header card-header-with-back">
						<button className="back-btn" onClick={this.goBack}>
							Revenir en arrière
						</button>
						<h2>Mes Baux</h2>
					</div>
					<div className="card-body table-wrapper">
						{this.state.are_loading_leases === false ? (
							leases.length ? (
								<TableLeases
									cols={[
										'LIBELLÉ',
										'LOCATAIRE',
										'DATE DE FIN',
										'PROCHAIN LOYER',
										'DÉTAILS',
									]}
									table={leases}
									displayLease={this.getLeaseDetails}
								/>
							) : (
								<p>Aucun bail disponible sur cette propriété.</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
				<Modal
					id="lease-modal"
					is_visible={this.state.display_lease_modal}
					callback={this.toggleModal}
				>
					{this.state.is_loading_lease === false ? (
						<Fragment>
							<div className="modal-header">
								<h2>
									Bail{' '}
									{this.props.leases.current
										? this.props.leases.current.label
										: null}
								</h2>
							</div>
							{this.props.leases.current ? (
								<TableLease table={this.props.leases.current} />
							) : (
								<p>
									Il n'y a aucune donnée supplémentaires pour ce bail.
								</p>
							)}
						</Fragment>
					) : (
						<Loading />
					)}
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		properties: state.properties,
		user: state.user,
		leases: state.leases,
	};
};
const mapDispacthToProps = {
	setLeases,
	setCurrentLease,
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(MyLeases));
