//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';

class AsideNav extends Component {
	render() {
		if (Object.keys(this.props.properties.current).length) {
			return (
				<nav>
					<ul>
						<li>
							<NavLink
								to={`/property/${this.props.properties.current.id}/dashboard`}
							>
								Tableau de bord
							</NavLink>
						</li>
						<li>
							<NavLink
								to={`/property/${this.props.properties.current.id}/data`}
							>
								Mes données
							</NavLink>
						</li>
						<li>
							<NavLink
								to={`/property/${this.props.properties.current.id}/incidents`}
							>
								Incidents
							</NavLink>
						</li>
						<li>
							<NavLink
								to={`/property/${this.props.properties.current.id}/account`}
							>
								Mon compte
							</NavLink>
						</li>
						<li>
							<NavLink
								to={`/property/${this.props.properties.current.id}/managment`}
							>
								Ma gestion
							</NavLink>
						</li>
						<li>
							<NavLink
								to={`/property/${this.props.properties.current.id}/bad_news`}
							>
								Mes relances / procédures
							</NavLink>
						</li>
						<li>
							<NavLink
								to={`/property/${this.props.properties.current.id}/files`}
							>
								Mes fichiers
							</NavLink>
						</li>
						<li className="contact-link">
							<NavLink
								to={`/property/${this.props.properties.current.id}/contact`}
							>
								Contact
							</NavLink>
						</li>
					</ul>
				</nav>
			);
		} else {
			return (
				<nav>
					<ul>
						<li>
							<NavLink exact to="/">
								Mes propriétés
							</NavLink>
						</li>
						<li>
							<NavLink to="/info">Mes informations</NavLink>
						</li>
					</ul>
				</nav>
			);
		}
	}
}

const mapStateToProps = state => {
	return {
		properties: state.properties,
	};
};

export default withRouter(connect(mapStateToProps)(AsideNav));
