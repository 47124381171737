export function setAnimatedCellsHeight() {
	const animatedCells = document.getElementsByClassName('animated-cell');
	for (let cell of animatedCells) {
		cell.style.height = `${cell.scrollHeight}px`;
	}
}

/**
 * @param {string} flag
 */
export function toggleAnimatedCells(flag) {
	let can_be_hidden = document.querySelectorAll(`.hideable.${flag}`);
	if (can_be_hidden[0] && can_be_hidden[0].classList.contains('hidden')) {
		for (let el of can_be_hidden) {
			el.classList.remove('hidden');
		}
	} else {
		for (let el of can_be_hidden) {
			el.classList.add('hidden');
		}
	}
}

export function resetAnimatedCells() {
	const can_be_hidden = document.getElementsByClassName(`hideable`);
	for (let el of can_be_hidden) {
		if (!el.classList.contains('hidden')) {
			el.classList.add('hidden');
		}
	}
}
