// module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
// component
import Form from '../../../components/form/Form';
import Loading from '../../../components/Loading';
// actions
import { setLog } from '../../../action_creators/logActionCreators';
// functions
import { postRequest, responseManagment } from '../../../functions/ajax';

class Help extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
		};

		this.postMessage = this.postMessage.bind(this);
		this.responseManagment = responseManagment.bind(this);
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async postMessage(form_value) {
		this.setState({ is_loading: true });
		const response = await postRequest(
			`/message-client?`
				+ (this.props.properties.current.id
					? `activity=${this.props.properties.current.id}`
					: ''),
			form_value,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setLog({
				type: 'success',
				message:
					'Message envoyé avec succès. Nous vous répondrons dans les plus bref délais.',
			});
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	render() {
		return (
			<div className="grid grid-1 contact">
				<div className="card">
					<div className="card-header">
						<h2>Aide</h2>
					</div>
					<div className="card-body">
						{/* eslint-disable-next-line react/jsx-no-target-blank */}
						<a
							className="btn download-btn btn-orange"
							href={`/pdf/MY${
								this.props.properties.current.sousTraitance === true
									? 'GP'
									: ''
							}-Guide-Proprietaire.pdf`}
							target="_blank"
						>
							Télécharger le guide
						</a>
						<h3>Contactez le service client de l'application&nbsp;:</h3>
						<p>
							Cet espace de contact est uniquement réservé aux{' '}
							<b>problèmes techniques</b> que vous pourriez relever sur le
							site. Pour toutes demandes relatives à la gestion de votre
							compte, merci de contacter votre gestionnaire via l’onglet{' '}
							<b>Contact</b> qui se trouve en haut à droite de cette page.
						</p>
						<blockquote>
							Votre message va être envoyé à&nbsp;:
							contact@monespaceproprietaire.com
						</blockquote>
						{this.state.is_loading === false ? (
							<Form
								form_items={[
									{
										type: 'text',
										name: 'object',
										label: `Objet`,
										required: true,
									},
									{
										type: 'textarea',
										name: 'message',
										label: `Message`,
										requied: true,
									},
								]}
								callback={this.postMessage}
							/>
						) : (
							<Loading />
						)}
					</div>
					<div className="card-footer">
						<Link to="/legal-notice">Mentions Légales</Link>
						<a
							href="/pdf/CGU-MonEspaceProprietaire.pdf"
							// eslint-disable-next-line react/jsx-no-target-blank
							target="_blank"
						>
							Conditions Générales d'Utilisation
						</a>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.user,
		properties: state.properties,
	};
};
const mapDispatchToProps = {
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Help));
