//module
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
// components
import Loading from '../../../components/Loading';
import TableTenants from '../../../components/table/TableTenants';
import GraphColumns from '../../../components/graph/GraphColumns';
import OwnerAccountCard from './cards/OwnerAccountCard';
// action
import {
	setTenants,
	setSoldeTenants,
	setTenantsExercice,
} from '../../../action_creators/tenantsActionCreators';
import { setLog } from '../../../action_creators/logActionCreators';
// functions
import { getRequest, responseManagment } from '../../../functions/ajax';
// constants
import { yellow } from '../../../constants/colors';

class MyAccount extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
		};

		this.responseManagment = responseManagment.bind(this);
	}

	UNSAFE_componentWillMount() {
		if (!Object.keys(this.props.tenants.list).length) {
			this.getTenants();
		}
	}

	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async getTenants() {
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/compte-locataire?activity=${this.props.properties.current.id}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setTenants(response.tenants, response.current_page);
			this.props.setSoldeTenants(response.solde, response.solde_tresorerie);
			this.props.setTenantsExercice(response.exercice);
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	render() {
		return (
			<div className="grid grid-3 my-account">
				<OwnerAccountCard />
				<div className="card unscrollable">
					<div className="card-header composed-card-header">
						<h2>état comptes locataires</h2>
						{this.props.tenants.exercice ? (
							<span className="item-select-disable">
								Période&nbsp;: {this.props.tenants.exercice}
							</span>
						) : null}
					</div>
					<div
						className={
							'card-body'
							+ (this.props.tenants.solde_treasury
							|| this.props.tenants.solde_treasury === 0
								? ' solde-tenants-global'
								: '')
						}
					>
						{this.state.is_loading === false ? (
							this.props.tenants.solde_treasury
							|| this.props.tenants.solde_treasury === 0 ? (
								<GraphColumns
									id="soldeAccountGraph"
									cols={1}
									colors={[yellow]}
									datas={[
										[
											'Solde global dû par vos locataires',
											-this.props.tenants.solde_treasury || null,
										],
									]}
								/>
							) : (
								<p>Solde indisponible</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
				<div className="card">
					<div className="card-header composed-card-header">
						<h2>Comptes locataires</h2>
						{this.props.tenants.exercice ? (
							<span className="item-select-disable">
								Période&nbsp;: {this.props.tenants.exercice}
							</span>
						) : null}
					</div>
					<div className="card-body table-wrapper">
						{this.state.is_loading === false ? (
							this.props.tenants.list[1]
							&& this.props.tenants.list[1].length ? (
								<Fragment>
									<TableTenants
										cols={[
											'NOM',
											'SOLDE',
											'SOLDE AVEC CHÈQUE/PRLT NON ENCAISSE',
											'COMPTE',
										]}
										table={this.props.tenants.list[1].slice(0, 4)}
										solde={this.props.tenants.solde}
										solde_treasury={this.props.tenants.solde_treasury}
										url_access={`/property/${this.props.match.params.id}/account/tenant_account/`}
									/>
									{this.props.tenants.list[1].length > 4 && (
										<div className="btn-wrapper">
											<Link
												className="btn"
												to={`/property/${this.props.match.params.id}/account/tenants`}
											>
												Voir tout
											</Link>
										</div>
									)}
								</Fragment>
							) : (
								<p>Il n'y aucun locataire.</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		properties: state.properties,
		user: state.user,
		tenants: state.tenants,
	};
};

const mapDispacthToProps = {
	setTenants,
	setSoldeTenants,
	setTenantsExercice,
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(MyAccount));
