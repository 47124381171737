// module
import React from 'react';
import PropTypes from 'prop-types';
import ToggleButton from './ToggleButton';

function TrippleToggleButtons(props) {
	return (
		<div className="toggle-btn-wrapper">
			<ToggleButton
				active_value={true}
				current_value={props.value}
				label={props.label_true}
				toggle={value => props.toggle(value)}
			/>
			<ToggleButton
				active_value={false}
				current_value={props.value}
				label={props.label_false}
				toggle={value => props.toggle(value)}
			/>
			<ToggleButton
				active_value={null}
				current_value={props.value}
				label={props.label_all}
				toggle={value => props.toggle(value)}
			/>
		</div>
	);
}

TrippleToggleButtons.propTypes = {
	value: PropTypes.any,
	toggle: PropTypes.func.isRequired,
	label_false: PropTypes.string.isRequired,
	label_true: PropTypes.string.isRequired,
	label_all: PropTypes.string.isRequired,
};

export default TrippleToggleButtons;
