// module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import Table from '../../../components/table/Table';
import Loading from '../../../components/Loading';
import PrintButton from '../../../components/button/PrintButton';
//action
import { setLots } from '../../../action_creators/lotsActionCreators';
import { setLog } from '../../../action_creators/logActionCreators';
// functions
import { getRequest, responseManagment } from '../../../functions/ajax';
import { printTable } from '../../../functions/print';


class MyLots extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
		};

		this.handlePrint = this.handlePrint.bind(this);
		this.goBack = this.goBack.bind(this);
		this.responseManagment = responseManagment.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.getLots();
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async getLots() {
		if (!this.props.lots.length) {
			this.setState({ is_loading: true });
			const response = await getRequest(
				`/lot?activity=${this.props.properties.current.id}`,
				this.props.user.token
			);
			if (this.responseManagment(response)) {
				this.props.setLots(response.lots);
			}

			if (this._isMounted) {
				this.setState({ is_loading: false });
			}
		}
	}

	handlePrint(evt) {
		printTable(
			'table-lots',
			'lots-propriete.pdf',
			'Mes lots',
			this.props.properties.current
		);
	}

	goBack(evt) {
		this.props.history.push(`/property/${this.props.match.params.id}/data`);
	}

	render() {
		return (
			<div className="grid grid-1 my-lots">
				<div className="card">
					<div className="card-header composed-card-header card-header-with-back">
						<button className="back-btn" onClick={this.goBack}>
							Revenir en arrière
						</button>
						<h2>
							Mes Lots
							{this.props.lots.length ? ` (${this.props.lots.length})` : ''}
						</h2>
						<em>à la date du jour</em>
						<PrintButton print={this.handlePrint} />
					</div>
					<div className="card-body table-wrapper">
						{this.state.is_loading === false ? (
							this.props.lots.length ? (
								<Table
									table={this.props.lots}
									keys={['reference', 'label', 'type', 'principal']}
									cols={['REF', 'LIBELLÉ', 'TYPE', 'PRINCIPAL']}
									boolean={['Non', 'Oui']}
									className="table-lots"
								/>
							) : (
								<p>Vous ne possédez pas de lots.</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		properties: state.properties,
		user: state.user,
		lots: state.lots,
	};
};
const mapDispacthToProps = {
	setLots,
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(MyLots));
