import * as types from '../action_types/profitActionTypes';

export const setGlobalProfits = (amounts) => ({
	type: types.SET_GLOBAL_PROFITS,
	amounts,
});
export const setProfitByLot = (profit) => ({
	type: types.SET_PROFIT_BY_LOT,
	profit,
});
export const setProfitExercices = (exercices) => ({
	type: types.SET_PROFIT_EXERCICES,
	exercices,
});
export const setProfitCurrentExercice = (exercice) => ({
	type: types.SET_PROFIT_CURRENT_EXERCICE,
	exercice,
});
export const setProfitLots = (lots) => ({
	type: types.SET_PROFIT_LOTS,
	lots,
});
export const setProfitCurrentLot = (lot) => ({
	type: types.SET_PROFIT_CURRENT_LOT,
	lot,
});
export const removeProfit = () => ({
	type: types.REMOVE_PROFIT,
});
