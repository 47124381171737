import * as types from '../action_types/banksActionTypes';

const initialeState = {
    list: [],
    default_bank: 0,
    current_bank: 0,
    transactions: [],
    graph: {},
    exercices: [],
    current_exercice: 0,
};

function banksReducer(state = initialeState, action) {
    let nextState;

    switch (action.type) {
        case types.SET_BANKS:
            let default_bank = 0;
            action.banks.forEach((bank, i) => {
                if (bank.default === true) {
                    default_bank = i;
                }
            });
            nextState = {
                ...state,
                list: action.banks,
                default_bank
            };
            if (!state.list.length) {
                nextState.current_bank = default_bank;
            }
            if (action.graph) {
                nextState.graph = action.graph;
            }
            return nextState || state;

        case types.SET_BANK_GRAPH:
            nextState = {
                ...state,
                graph: action.graph
            };
            return nextState || state;

        case types.SET_BANK_TRANSACTIONS:
            nextState = {
                ...state,
                transactions: action.transactions || {},
            };
            return nextState || state;

        case types.SET_BANKS_EXERCICES:
            nextState = {
                ...state,
                exercices: action.exercices || []
            };
            return nextState || state;

        case types.SET_BANKS_CURRENT_EXERCICE:
            nextState = {
                ...state,
                current_exercice: action.exercice
            };
            return nextState || state;

        case types.SET_CURRENT_BANK:
            nextState = {
                ...state,
                current_bank: action.index,
                transactions: {},
                graph: {},
            };
            return nextState || state;

        case types.REMOVE_BANKS:
            return initialeState;

        default:
            return state;
    }
}

export default banksReducer; 