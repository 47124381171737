import * as types from '../action_types/proceduresActionTypes';

// PROCEDURES
export const setProcedures = procedures => ({
	type: types.SET_PROCEDURES,
	procedures,
});
export const setShortProcedures = procedures => ({
	type: types.SET_SHORT_PROCEDURES,
	procedures,
});
export const removeProcedures = () => ({
	type: types.REMOVE_PROCEDURES,
});
