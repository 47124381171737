import {
	SET_REMINDERS,
	SET_SHORT_REMINDERS,
	SET_REMINDERS_EXERCICES,
	SET_REMINDERS_CURRENT_EXERCICE,
	REMOVE_REMINDERS,
} from '../action_types/remindersActionTypes';

const initialState = {
	exercices: [],
	current_exercice: 0,
	list: [],
	shortList: [],
};

function accountReducer(state = initialState, action) {
	let nextState;

	switch (action.type) {
		case SET_REMINDERS:
			nextState = {
				...state,
				list: action.reminders,
			};
			return nextState || state;

		case SET_SHORT_REMINDERS:
			nextState = {
				...state,
				shortList: action.reminders,
			};
			return nextState || state;

		case SET_REMINDERS_EXERCICES:
			nextState = {
				...state,
				exercices: action.exercices,
			};
			return nextState || state;

		case SET_REMINDERS_CURRENT_EXERCICE:
			nextState = {
				...state,
				current_exercice: action.exercice,
			};
			return nextState || state;

		case REMOVE_REMINDERS:
			return initialState;

		default:
			return state;
	}
}

export default accountReducer;
