import * as types from '../action_types/tenantsActionTypes';

const initialState = {
    list: {},
    nb_pages: 0,
    current: 0,
    solde: 0,
    exercice: ''
};

function councilReducer(state = initialState, action) {
    let nextState;

    switch (action.type) {

        case types.SET_TENANTS:
            nextState = {
                ...state,
                list: {
                    ...state.list
                }
            };
            nextState.list[action.current_page] = action.tenants;
            return nextState || state;

        case types.SET_NB_PAGES_TENANTS:
            nextState = {
                ...state,
                nb_pages: action.nb
            };
            return nextState || state;

        case types.SET_SOLDE_TENANTS:
            nextState = {
                ...state,
                solde: action.solde,
                solde_treasury: action.solde_treasury
            };
            return nextState || state;

            case types.SET_TENANTS_EXERCICE:
                nextState = {
                    ...state,
                    exercice: action.exercice
                };
                return nextState || state;

        case types.REMOVE_TENANTS:
            return initialState;

        default:
            return state;
    }
}

export default councilReducer;