import * as types from '../action_types/accountActionTypes';

// ACCOUNT
export const removeAccount = () => ({
	type: types.REMOVE_ACCOUNT
});
export const setGlobalAccountAmounts = (amounts) => ({
	type: types.SET_GLOBAL_ACCOUNT_AMOUNTS,
	amounts
});
export const setAccountExercices = (exercices) => ({
	type: types.SET_ACCOUNT_EXERCICES,
	exercices
});
export const setAccountCurrentExercice = (exercice) => ({
	type: types.SET_ACCOUNT_CURRENT_EXERCICE,
	exercice
});
export const setAccountTransactions = (transactions) => ({
	type: types.SET_ACCOUNT_TRANSACTIONS,
	transactions
});
export const setAccountNumber = (number) => ({
	type: types.SET_ACCOUNT_NUMBER,
	number
});
// REMINDERS
export const setReminders = (reminders, paid) => ({
	type: types.SET_REMINDERS,
	reminders,
	paid
});
export const setRemindersExercices = (exercices) => ({
	type: types.SET_REMINDERS_EXERCICES,
	exercices
});
export const saveUnpaidReminders = () => ({
	type: types.SAVE_UNPAID_REMINDERS
});
export const saveGlobalReminders = () => ({
	type: types.SAVE_GLOBAL_REMINDERS
});
export const setRemindersCurrentExercice = (exercice) => ({
	type: types.SET_REMINDERS_CURRENT_EXERCICE,
	exercice
});
export const removeReminders = () => ({
	type: types.REMOVE_REMINDERS
});