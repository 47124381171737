import { black, blue, orange } from '../constants/colors';

export function createBankGraph(graph) {
	let series = Object.entries(graph.years).map(([key, value], i, array) => {
		let serie = { name: key, data: value };
		if (i === array.length - 1) {
			serie.visible = true;
		} else {
			serie.visible = false;
		}
		// eslint-disable-next-line default-case
		switch (i) {
			case 0:
				serie.color = black;
				break;
			case 1:
				serie.color = blue;
				break;
			case 2:
				serie.color = orange;
				break;
		}
		return serie;
	});
	return { x: graph.months, series };
}
