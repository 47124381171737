//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import TableReminders from '../../../components/table/TableReminders';
import { SelectorItemObject } from '../../../components/form/item/SelectorItem';
import Loading from '../../../components/Loading';
//action
import {
	setReminders,
	setRemindersExercices,
	setRemindersCurrentExercice,
} from '../../../action_creators/remindersActionCreators';
import { setLog } from '../../../action_creators/logActionCreators';
// functions
import { getRequest, responseManagment } from '../../../functions/ajax';

class MyReminders extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = { is_loading: false };

		this.responseManagment = responseManagment.bind(this);
		this.handleExercice = this.handleExercice.bind(this);
		this.goBack = this.goBack.bind(this);
	}

	UNSAFE_componentWillMount() {
		if (!this.props.reminders.list.length) {
			this.getReminders();
		}
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.reminders.current_exercice !== this.props.reminders.current_exercice
		) {
			// exercice change
			this.updateReminders();
		}
	}

	async getReminders() {
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/relance?activity=${this.props.properties.current.id}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setRemindersExercices(response.exercices);
			this.props.setReminders(response.relances);
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	async updateReminders() {
		const { reminders, properties } = this.props;
		const exercice = reminders.exercices[reminders.current_exercice];
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/relance?activity=${properties.current.id}`
				+ `&start=${exercice.start}`
				+ `&end=${exercice.end}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setRemindersExercices(response.exercices);
			this.props.setReminders(response.relances);
		} else {
			this.props.setReminders({});
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	goBack(evt) {
		this.props.history.push(`/property/${this.props.match.params.id}/bad_news/`);
	}

	handleExercice(evt) {
		this.props.setRemindersCurrentExercice(evt.target.value);
	}

	render() {
		const { reminders, properties } = this.props;
		return (
			<div className="grid grid-1">
				<div className="card">
					<div className="card-header card-header-with-back">
						<button className="back-btn" onClick={this.goBack}>
							Revenir en arrière
						</button>
						<h2>Mes relances</h2>
					</div>
					<div className="card-body table-wrapper">
						<div className="standfirst toggle-plus-select">
							{reminders.exercices.length > 0 && (
								<SelectorItemObject
									id="select-reminders-exerice"
									label="Période"
									current={reminders.current_exercice}
									options={reminders.exercices}
									callback={this.handleExercice}
								/>
							)}
						</div>
						{this.state.is_loading === false ? (
							reminders.list.length ? (
								<TableReminders
									cols={[
										'DATE',
										'MONTANT',
										'RESTANT',
										'TYPE',
										'DOCUMENTS',
									]}
									table={reminders.list}
									picto_color={properties.current.syndic}
									property={properties.current.id}
								/>
							) : null
						) : (
							<Loading />
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		properties: state.properties,
		user: state.user,
		reminders: state.reminders,
	};
};

const mapDispacthToProps = {
	setReminders,
	setRemindersExercices,
	setRemindersCurrentExercice,
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(MyReminders));
