import * as types from '../action_types/propertiesActionTypes';

export const setProperties = (properties) => ({
	type: types.SET_PROPERTIES,
	properties
});
export const removeProperties = () => ({
	type: types.REMOVE_PROPERTIES
});
export const setCurrentProperty = (property) => ({
	type: types.SET_CURRENT_PROPERTY,
	property
});

export const removeCurrentProperty = () => ({
	type: types.REMOVE_CURRENT_PROPERTY
});