// module
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// components
import { TableBase, colorOddRows } from './Table';
import TdAmount from './TdAmount';
import FileDownloadedFromAPI from '../FileDownloadedFromAPI';
// functions
import { chooseColor } from '../../functions';

class TableReminders extends Component {
	render() {
		return (
			<TableBase className="table-reminders" cols={this.props.cols}>
				<tbody>
					{this.props.table.map((el, i) => (
						<tr key={i} className={colorOddRows(i)}>
							<td className="date">{el.date}</td>
							<TdAmount value={el.amount} negative={true} />
							<TdAmount value={el.leftAmount} negative={true} />
							<td>{el.type}</td>
							<td>
								{el.facture === true && (
									<FileDownloadedFromAPI
										uri={
											`/relance-facture?activity=${this.props.property}`
											+ `&id_relance=${el.id_relance}`
											+ `&id_relance_element=${el.id_relance_element}`
										}
										filename={`${el.type}-relance-${el.date}-${el.id_relance}`}
										className="picto-table-wrapper"
									>
										<img
											src={`/images/pictogrammes/invoice-${chooseColor(
												this.props.picto_color
											)}.svg`}
											alt="Facture de la relance"
											title="Télécharger de la relance"
											className={`table-picto`}
										/>
									</FileDownloadedFromAPI>
								)}
								{el.courrier === true && (
									<FileDownloadedFromAPI
										uri={
											`/relance-courrier?activity=${this.props.property}`
											+ `&id_relance=${el.id_relance}`
											+ `&id_relance_element=${el.id_relance_element}`
										}
										filename={`${el.type}-relance-${el.date}-${el.id_relance}`}
										className="picto-table-wrapper"
									>
										<img
											src={`/images/pictogrammes/pdf-${chooseColor(
												this.props.picto_color
											)}.svg`}
											title="Télécharger le courrier de relance"
											alt="Courrier de la relance"
											className={`table-picto`}
										/>
									</FileDownloadedFromAPI>
								)}
							</td>
						</tr>
					))}
				</tbody>
			</TableBase>
		);
	}
}

TableReminders.propTypes = {
	// ...Table.propTypes,
	picto_color: PropTypes.string,
	property: PropTypes.number.isRequired,
};

export default TableReminders;
