//module
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
// components
import GraphColumns from '../../../../components/graph/GraphColumns';
import Loading from '../../../../components/Loading';
//action
import { setLog } from '../../../../action_creators/logActionCreators';
import {
	setGlobalAccountAmounts,
	setAccountExercices,
	setAccountCurrentExercice,
} from '../../../../action_creators/accountActionCreators';
// functions
import { getRequest, responseManagment } from '../../../../functions/ajax';
// constants
import { green, red } from '../../../../constants/colors';

class OwnerAccountCard extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
		};

		this.responseManagment = responseManagment.bind(this);
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async UNSAFE_componentWillMount() {
		if (!Object.keys(this.props.account.global_amounts).length) {
			this.getAccount();
		}
	}

	async getAccount() {
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/compte-proprietaire-graph?activity=${this.props.properties.current.id}`,
			this.props.user.token
		);
		if (
			this.responseManagment(response) &&
			// for displayed 'Aucun ... n'est disponible...'
			// better here than in render
			!(response.sum_rent === 0 && response.deposit === 0)
		) {
			this.props.setGlobalAccountAmounts({
				sum_rent: response.sum_rent,
				sum_deposit: response.sum_deposit,
				exercice: response.exercice,
			});
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	render() {
		const amounts = this.props.account.global_amounts;
		return (
			<div className="card unscrollable">
				<div className="card-header composed-card-header">
					<h2>Mon compte propriétaire</h2>
					<span className="item-select-disable">
						Période&nbsp;: {amounts.exercice}
					</span>
				</div>
				<div className="card-body">
					<div
						className={
							'card-body'
							+ (Object.keys(amounts).length
							&& this.state.is_loading === false
								? ' card-graph'
								: '')
						}
					>
						{this.state.is_loading === false ? (
							Object.keys(amounts).length ? (
								<Fragment>
									<GraphColumns
										id="accountGraph"
										cols={2}
										colors={[green, red]}
										datas={[
											[
												'Montants Appelés aux Locataires sur la période',
												amounts.sum_rent || null,
											],
											[
												'Montants Réglés aux Propriétaires sur la période',
												-amounts.sum_deposit || null,
											],
										]}
									/>
									<div className="btn-wrapper">
										<Link
											className="btn"
											to={`/property/${this.props.match.params.id}/account/owner_account`}
										>
											Accéder
										</Link>
									</div>
								</Fragment>
							) : (
								<p>Aucun montant n'est disponible pour cette période.</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		properties: state.properties,
		user: state.user,
		account: state.account,
	};
};
const mapDispatchToProps = {
	setGlobalAccountAmounts,
	setAccountExercices,
	setAccountCurrentExercice,
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OwnerAccountCard));
