// module
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// components
import { TableBase, colorOddRows } from './Table';
import TdAmount from './TdAmount';

class TableTenants extends Component {
	render() {
		return (
			<TableBase className="table-tenants" cols={this.props.cols}>
				<tbody>
					{this.props.table.map((tenant, i) => (
						<tr key={i} className={colorOddRows(i)}>
							<td className="td-align-left">{tenant.name}</td>
							<TdAmount value={tenant.solde} />
							<TdAmount value={tenant.solde_tresorerie} />
							<td>
								<Link
									to={this.props.url_access + tenant.id}
									className="table-picto"
								>
									Accès au compte du locataire
								</Link>
							</td>
						</tr>
					))}
					<tr className="total">
						<td>TOTAL</td>
						<TdAmount value={this.props.solde} />
						<TdAmount value={this.props.solde_treasury} />
						<td></td>
					</tr>
				</tbody>
			</TableBase>
		);
	}
}

export default TableTenants;
