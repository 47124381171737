export const black = '#000000';
export const white = '#ffffff';
export const grey = '#707070';
export const light_grey = '#cecece';
export const pale_grey = '#e5e5e5';
export const green = '#1c9739';
export const light_green = '#cadacf';
export const pale_green = '#d7e3db';
export const dark_green = '#366652';
export const blue = '#336699';
export const light_blue = '#cedee2';
export const pale_blue = '#dee9ec';
export const dark_blue = '#005962';
export const orange = '#ff9913';
export const yellow = '#FFB93C';
export const half_yellow = '#edcca6';
export const light_yellow = '#f4e1c9';
export const pale_yellow = '#fbf5ed';
export const red = '#c73636';
export const dark_red = '#992820';
