// module
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// components
import { TableBase, colorOddRows } from './Table';
import { formateStringDate } from '../../functions';
import FileDownloadedFromAPI from '../FileDownloadedFromAPI';
import DropDownButton from '../button/DropdownButton';

class TableCrg extends Component {
	render() {
		return (
			<TableBase className="table-crg" cols={this.props.cols}>
				<tbody>
					{this.props.table.map((crg, i) => (
						<RowCrg key={i} crg={crg} i={i} activity={this.props.activity} />
					))}
				</tbody>
			</TableBase>
		);
	}
}

class RowCrg extends Component {
	constructor(props) {
		super(props);

		this.state = {
			dropdownOpen: false,
		};

		this.toggleDropdown = this.toggleDropdown.bind(this);
		this.closeDropdown = this.closeDropdown.bind(this);
	}

	toggleDropdown = e => {
		e.preventDefault();
		this.setState({ dropdownOpen: !this.state.dropdownOpen });
	};
	closeDropdown = e => {
		if (this.state.dropdownOpen) {
			this.setState({ dropdownOpen: false });
		}
	};

	render() {
		const yearFoncier = this.props.crg.foncier
			? this.props.crg.foncier.slice(0, 4)
			: null;
		const endYear = this.props.crg.end.slice(0, 4);
		const annual = this.props.crg.end.endsWith('-12-31');

		return (
			<tr className={colorOddRows(this.props.i)} onClick={this.closeDropdown}>
				<td className="date">{formateStringDate(this.props.crg.start)}</td>
				<td className="date">{formateStringDate(this.props.crg.end)}</td>
				<td>
					<DropDownButton
						open={this.state.dropdownOpen}
						toggle={this.toggleDropdown}
						items={[
							<FileDownloadedFromAPI
								uri={
									`/crg-pdf?`
									+ `activity=${this.props.activity}`
									+ `&id_exercice=${this.props.crg.id}`
								}
								filename={`CRG-${this.props.crg.start}-${this.props.crg.end}`}
							>
								<img
									src={`/images/pictogrammes/pdf-landscape-blue.svg`}
									alt="Télécharger le CRG"
									className="table-picto"
								/>{' '}
								Compte rendu de gestion de {this.props.crg.start} en
								paysage
							</FileDownloadedFromAPI>,
							<FileDownloadedFromAPI
								uri={
									`/crg-pdf?`
									+ `activity=${this.props.activity}`
									+ `&id_exercice=${this.props.crg.id}`
									+ `&portrait=1`
								}
								filename={`CRG-${this.props.crg.start}-${this.props.crg.end}`}
							>
								<img
									src={`/images/pictogrammes/pdf-blue.svg`}
									alt="Télécharger le CRG"
									className="table-picto"
								/>{' '}
								Compte rendu de gestion de {this.props.crg.start} en
								portrait
							</FileDownloadedFromAPI>,
							yearFoncier ? (
								<FileDownloadedFromAPI
									uri={
										`/crg-pdf?`
										+ `activity=${this.props.activity}`
										+ `&id_exercice=${this.props.crg.id}`
										+ `&foncier=true`
									}
									filename={`Declaration-revenus-foncier-${yearFoncier}`}
									className="picto-table-wrapper"
								>
									<img
										src={`/images/pictogrammes/pdf-second-blue.svg`}
										alt="Télécharger la déclaration des revenus fonciers"
										className="table-picto"
									/>{' '}
									Déclaration des revenus fonciers de {yearFoncier}
								</FileDownloadedFromAPI>
							) : null,
							annual && (
								<FileDownloadedFromAPI
									uri={
										`/crg-pdf?`
										+ `activity=${this.props.activity}`
										+ `&id_exercice=${this.props.crg.id}`
										+ `&annual=true`
									}
									filename={`CRG-annuel-${endYear}`}
									className="picto-table-wrapper"
								>
									<img
										src={`/images/pictogrammes/pdf-landscape-grey.svg`}
										alt="Télécharger le CRG annuel"
										className="table-picto"
									/>{' '}
									Compte rendu de gestion annuel de {endYear} en paysage
								</FileDownloadedFromAPI>
							),
							annual && (
								<FileDownloadedFromAPI
									uri={
										`/crg-pdf?`
										+ `activity=${this.props.activity}`
										+ `&id_exercice=${this.props.crg.id}`
										+ `&annual=true`
										+ `&portrait=true`
									}
									filename={`CRG-annuel-${endYear}`}
									className="picto-table-wrapper"
								>
									<img
										src={`/images/pictogrammes/pdf-grey.svg`}
										alt="Télécharger le CRG annuel"
										className="table-picto"
									/>{' '}
									Compte rendu de gestion annuel de {endYear} en
									portrait
								</FileDownloadedFromAPI>
							),
						]}
					>
						<img
							src={`/images/pictogrammes/pdf-cogs-white.svg`}
							alt="Option de téléchargement"
							title="Option de téléchargement"
							className="table-picto"
						/>
					</DropDownButton>
				</td>
			</tr>
		);
	}
}

TableCrg.propTypes = {
	// ...Table.propTypes,
	activity: PropTypes.number.isRequired,
};

export default TableCrg;
