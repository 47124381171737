//module
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
// components
import { SelectorItemObject } from '../../../../components/form/item/SelectorItem';
import GraphColumns from '../../../../components/graph/GraphColumns';
import Loading from '../../../../components/Loading';
//action
import { setLog } from '../../../../action_creators/logActionCreators';
import {
	setGlobalProfits,
	setProfitExercices,
	setProfitCurrentExercice,
} from '../../../../action_creators/profitActionCreators';
// functions
import { getRequest, responseManagment } from '../../../../functions/ajax';
import { blue, green, red } from '../../../../constants/colors';

class ProfitCard extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
		};

		this.responseManagment = responseManagment.bind(this);
		this.handleExercice = this.handleExercice.bind(this);
	}

	async UNSAFE_componentWillMount() {
		const profit = this.props.profit;
		const history = profit.history;
		if (
			!Object.keys(profit.amounts).length ||
			// Update transactions if exerice has change in another page
			profit.current_exercice !== history.global.exercice
		) {
			this.getProfit();
		}
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.profit.current_exercice !== this.props.profit.current_exercice) {
			this.updateProfit();
		}
	}

	// get and update value should be the same do to cross exercices and operation
	async getProfit() {
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/rentabilite-des-lots?activity=${this.props.properties.current.id}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setProfitExercices(response.exercices);
			this.props.setGlobalProfits(response.profit);
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	async updateProfit() {
		const current_exercice =
			this.props.profit.exercices[this.props.profit.current_exercice];
		this.setState({ is_loading: true });

		const response = await getRequest(
			`/rentabilite-des-lots?`
				+ `activity=${this.props.properties.current.id}`
				+ `&start=${current_exercice.start}`
				+ `&end=${current_exercice.end}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setGlobalProfits(response.profit);
		} else {
			this.props.setGlobalProfits({});
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	handleExercice(evt) {
		this.props.setProfitCurrentExercice(evt.target.value);
	}

	render() {
		return (
			<div
				className={
					`card unscrollable profit`
					+ (Object.keys(this.props.profit.amounts).length
					&& this.state.is_loading === false
						? ' card-graph'
						: '')
				}
			>
				<div className="card-header">
					<h2>Rentabilité des lots</h2>
				</div>
				<div
					className={
						'card-body'
						+ (Object.keys(this.props.profit.amounts).length
						&& this.state.is_loading === false
							? ' card-graph'
							: '')
					}
				>
					{this.state.is_loading === false ? (
						Object.keys(this.props.profit.amounts).length ? (
							<Fragment>
								<div className="standfirst">
									{this.props.profit.exercices.length > 0 && (
										<SelectorItemObject
											id={`select-card-profit-exercices`}
											label="Période"
											current={this.props.profit.current_exercice}
											options={this.props.profit.exercices}
											callback={this.handleExercice}
										/>
									)}
								</div>
								<GraphColumns
									id="exerciceGraph"
									cols="3"
									colors={[green, red, blue]}
									datas={[
										[
											'Montant total des Loyers',
											this.props.profit.amounts.rents || null,
										],
										[
											'Montant total des Charges',
											this.props.profit.amounts.spent || null,
										],
										['Résultat', this.props.profit.amounts.result],
									]}
								/>
								<div className="btn-wrapper">
									<Link
										className="btn"
										to={`/property/${this.props.match.params.id}/managment/profit`}
									>
										Accéder
									</Link>
								</div>
							</Fragment>
						) : (
							<p>Aucune rente n'est disponible pour cette période.</p>
						)
					) : (
						<Loading />
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		properties: state.properties,
		user: state.user,
		profit: state.profit,
	};
};
const mapDispatchToProps = {
	setLog,
	setGlobalProfits,
	setProfitExercices,
	setProfitCurrentExercice,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfitCard));
