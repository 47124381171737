// modules
import { combineReducers } from 'redux';
// reducers
import user from './userReducer';
import properties from './propertiesReducer';
import leases from './leasesReducer';
import account from './accountReducer';
import admin from './adminReducer';
import profit from './profitReducer';
import tenants from './tenantsReducer';
import crg from './crgReducer';
import notifications from './notificationsReducer';
import banks from './banksReducer';
import lots from './lotsReducer';
import honoraries from './honorariesReducer';
import incidents from './incidentsReducer';
import reminders from './remindersReducer';
import procedures from './proceduresReducer';
import file_manager from './filesReducer';
import log from './logReducer';

const reducerCombiner = combineReducers({
	user,
	properties,
	leases,
	lots,
	banks,
	admin,
	account,
	tenants,
	crg,
	honoraries,
	profit,
	notifications,
	incidents,
	procedures,
	reminders,
	file_manager,
	log,
});

export default reducerCombiner;
