//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import {
	SelectorItemObject,
	SelectorItemBase,
} from '../../../components/form/item/SelectorItem';
import PrintButton from '../../../components/button/PrintButton';
import TableProfit from '../../../components/table/TableProfit';
import Loading from '../../../components/Loading';
//action
import { setLog } from '../../../action_creators/logActionCreators';
import {
	setProfitByLot,
	setProfitExercices,
	setProfitCurrentExercice,
	setProfitLots,
	setProfitCurrentLot,
} from '../../../action_creators/profitActionCreators';
// functions
import { getRequest, responseManagment } from '../../../functions/ajax';
import { printTable } from '../../../functions/print';

class Profit extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
		};

		this.goBack = this.goBack.bind(this);
		this.responseManagment = responseManagment.bind(this);
		this.handleExercice = this.handleExercice.bind(this);
		this.handleLot = this.handleLot.bind(this);
		this.handlePrint = this.handlePrint.bind(this);
		this.setOwnPrintFilters = this.setOwnPrintFilters.bind(this);
	}

	UNSAFE_componentWillMount() {
		const profit = this.props.profit;
		if (!Object.keys(profit.lots).length) {
			this.getProfit();
		} else if (
			// Exercice has changed in another component
			profit.current_exercice !== profit.history.detail.exercice
		) {
			this.updateProfit();
		}
	}

	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps) {
		const profit = this.props.profit;
		// Filter change exercice
		if (
			prevProps.profit.current_exercice !== profit.current_exercice
			|| prevProps.profit.current_lot !== profit.current_lot
		) {
			this.updateProfit();
		}
	}

	async getProfit() {
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/rentabilite-par-lot?activity=${this.props.properties.current.id}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setProfitExercices(response.exercices);
			this.props.setProfitLots(response.lots);
			this.props.setProfitCurrentLot(Object.keys(response.lots)[0]);
			this.props.setProfitByLot(response.profit);
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	async updateProfit() {
		this.setState({ is_loading: true });
		const current_exercice =
			this.props.profit.exercices[this.props.profit.current_exercice];
		const response = await getRequest(
			`/rentabilite-par-lot/`
				+ (Object.keys(this.props.profit.lots).length
					? this.props.profit.lots[this.props.profit.current_lot].id
					: '')
				+ `?activity=${this.props.properties.current.id}`
				+ `&start=${current_exercice.start}`
				+ `&end=${current_exercice.end}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setProfitByLot(response.profit);
		} else {
			this.props.setProfitByLot({});
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	handleLot(evt) {
		// parseInt for elvis operator
		this.props.setProfitCurrentLot(parseInt(evt.target.value));
	}
	handleExercice(evt) {
		this.props.setProfitCurrentExercice(evt.target.value);
	}
	handlePrint(evt) {
		printTable(
			'table-profit',
			`rentabilite-pour-le-lot${
				this.props.profit.lots[this.props.profit.current_lot].label
			}.pdf`,
			'Rentabilité du lot : '
				+ this.props.profit.lots[this.props.profit.current_lot].label,
			this.props.properties.current,
			this.setOwnPrintFilters
		);
	}

	setOwnPrintFilters() {
		return `Période : ${
			this.props.profit.exercices[this.props.profit.current_exercice].label
		} | Lots : ${this.props.profit.lots[this.props.profit.current_lot].label}`;
	}

	goBack(evt) {
		this.props.history.push(`/property/${this.props.match.params.id}/managment/`);
	}

	render() {
		return (
			<div className="grid grid-1 profit">
				<div className="card">
					<div className="card-header card-header-with-back">
						<button className="back-btn" onClick={this.goBack}>
							Revenir en arrière
						</button>
						<h2>Rentabilité par lots</h2>
						<PrintButton print={this.handlePrint} />
					</div>
					<div className="card-body table-wrapper">
						<div className="standfirst">
							{this.props.profit.exercices.length > 0 && (
								<SelectorItemObject
									id="select-profit-exerice"
									label="Période"
									current={this.props.profit.current_exercice}
									options={this.props.profit.exercices}
									callback={this.handleExercice}
								/>
							)}
							{Object.keys(this.props.profit.lots).length > 0 && (
								<SelectorItemBase
									id="select-profit-lots"
									label="Lots"
									current={this.props.profit.current_lot}
									callback={this.handleLot}
								>
									{Object.values(this.props.profit.lots).map(
										(lot, i) => (
											<option key={i} value={lot.id}>
												{lot.label}
											</option>
										)
									)}
								</SelectorItemBase>
							)}
						</div>
						{this.state.is_loading === false ? (
							Object.keys(this.props.profit.detail).length ? (
								<TableProfit
									cols={[
										' ',
										'MONTANT HT',
										'TVA',
										'MONTANT TTC',
										'PIECES',
									]}
									table={this.props.profit.detail}
									activity={this.props.properties.current.id}
								/>
							) : (
								<p>
									Il n'y aucun profit sur la propriété pour cette
									période.
								</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		properties: state.properties,
		user: state.user,
		profit: state.profit,
	};
};
const mapDispatchToProps = {
	setLog,
	setProfitByLot,
	setProfitExercices,
	setProfitCurrentExercice,
	setProfitLots,
	setProfitCurrentLot,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profit));
