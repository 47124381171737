// Account 
export const SET_GLOBAL_ACCOUNT_AMOUNTS = 'SET_GLOBAL_ACCOUNT_AMOUNTS';
export const SET_ACCOUNT_EXERCICES = 'SET_ACCOUNT_EXERCICES';
export const SET_ACCOUNT_CURRENT_EXERCICE = 'SET_ACCOUNT_CURRENT_EXERCICE';
export const SET_ACCOUNT_NUMBER = 'SET_ACCOUNT_NUMBER';
export const SET_ACCOUNT_TRANSACTIONS = 'SET_ACCOUNT_TRANSACTIONS';
export const REMOVE_ACCOUNT = 'REMOVE_ACCOUNT';
// Reminders
export const SET_REMINDERS = 'SET_REMINDERS';
export const SAVE_UNPAID_REMINDERS = 'SAVE_UNPAID_REMINDERS';
export const SAVE_GLOBAL_REMINDERS = 'SAVE_GLOBAL_REMINDERS';
export const SET_REMINDERS_EXERCICES = 'SET_REMINDERS_EXERCICES';
export const SET_REMINDERS_CURRENT_EXERCICE = 'SET_REMINDERS_CURRENT_EXERCICE';
export const REMOVE_REMINDERS = 'REMOVE_REMINDERS';