// module
import React from 'react';
import PropTypes from 'prop-types';
// components
import { TableBase, colorOddRows } from './Table';
import { formatNumbers } from '../../functions';

const TableLeases = props => (
	<TableBase className="table-leases" cols={props.cols}>
		<tbody>
			{props.table.map((lease, i) => (
				<tr
					key={i}
					className={colorOddRows(i)}
					onClick={evt => props.displayLease(lease.id)}
				>
					<td className="td-label">{lease.label}</td>
					<td>{lease.locataire}</td>
					<td>{lease.end}</td>
					<td>
						{lease.next_rent
							? `${lease.next_rent} - ${formatNumbers(
									lease.rent_amout
							  )}\u00A0€`
							: null}
					</td>
					<td>
						<img
							src="/images/pictogrammes/lens-blue.svg"
							alt="bouton détails"
							className={`table-picto`}
						/>
					</td>
				</tr>
			))}
		</tbody>
	</TableBase>
);

TableLeases.propTypes = {
	...TableBase.propTypes,
	displayLease: PropTypes.func.isRequired,
};

export default TableLeases;
