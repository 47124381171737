//module
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
// components
// import BudgetCard from "./cards/BudgetCard";
import ProfitCard from './cards/ProfitCard';
import TableCrg from '../../../components/table/TableCrg';
import TableHonoraries from '../../../components/table/TableHonoraries';
import Loading from '../../../components/Loading';
//action
import { setLog } from '../../../action_creators/logActionCreators';
import { setCrg } from '../../../action_creators/crgActionCreators';
import { setHonorariesShort } from '../../../action_creators/honorariesActionCreators';
// functions
import { getRequest, responseManagment } from '../../../functions/ajax';

class MyManagment extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading_crg: false,
			are_loading_honoraries: false,
		};

		this.responseManagment = responseManagment.bind(this);
	}

	async UNSAFE_componentWillMount() {
		this.getCrg();
		this.getHonoraries();
	}

	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async getCrg() {
		if (!this.props.crg.length) {
			this.setState({ is_loading_crg: true });
			const response = await getRequest(
				`/crg?activity=${this.props.properties.current.id}`,
				this.props.user.token
			);
			if (this.responseManagment(response)) {
				this.props.setCrg(response.crg);
			}

			if (this._isMounted) {
				this.setState({ is_loading_crg: false });
			}
		}
	}

	async getHonoraries() {
		if (!this.props.honoraries.short_list.length) {
			this.setState({ are_loading_honoraries: true });
			const response = await getRequest(
				`/honoraire?activity=${this.props.properties.current.id}&max=4`,
				this.props.user.token
			);
			if (this.responseManagment(response)) {
				this.props.setHonorariesShort(response.honoraires, response.count);
			}

			if (this._isMounted) {
				this.setState({ are_loading_honoraries: false });
			}
		}
	}

	render() {
		const honoraries = this.props.honoraries.short_list;
		return (
			<div className={'grid grid-3 my-managment'}>
				{/* <BudgetCard/> */}
				<div className="card">
					<div className="card-header">
						<h2>Compte rendu de gestion</h2>
					</div>
					<div className="card-body table-wrapper">
						{this.state.is_loading_crg === false ? (
							this.props.crg.length ? (
								<TableCrg
									activity={this.props.properties.current.id}
									cols={['DATE DE DÉBUT', 'DATE DE FIN', 'DOCUMENTS']}
									table={this.props.crg}
								/>
							) : (
								<p>Aucun CRG disponible.</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
				<div className="card">
					<div className="card-header">
						<h2>Honoraires / Factures / Autres Charges</h2>
					</div>
					<div className="card-body table-wrapper">
						{this.state.are_loading_honoraries === false ? (
							honoraries.length ? (
								<Fragment>
									<TableHonoraries
										table={honoraries}
										cols={[
											'DATE',
											'TYPE',
											'MONTANT',
											'MONTANT RESTANT',
											'DOCUMENTS',
										]}
										activity={this.props.properties.current.id}
									/>
									<div className="btn-wrapper">
										<Link
											className="btn"
											to={`/property/${this.props.match.params.id}/managment/honoraries`}
										>
											Voir tout
										</Link>
									</div>
								</Fragment>
							) : (
								<p>Vous n'avez aucun honoraires.</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
				<ProfitCard />
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		properties: state.properties,
		user: state.user,
		crg: state.crg,
		honoraries: state.honoraries,
	};
};
const mapDispatchToProps = {
	setLog,
	setCrg,
	setHonorariesShort,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyManagment));
