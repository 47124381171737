// module
import React from 'react';
// components
import PictoTable from '../PictoTable';
// function
import { formatNumbers } from '../../functions';

const TableLease = (props) => (
	<table className={`table horizontal table-lease`}>
		<tbody>
			<tr>
				<th>Libellé</th>
				<td>{props.table.label}</td>
			</tr>
			<tr>
				<th>Début</th>
				<td>{props.table.start}</td>
			</tr>
			<tr>
				<th>Fin</th>
				<td>{props.table.end}</td>
			</tr>
			<tr>
				<th>Prochain appel</th>
				<td>{props.table.next_call}</td>
			</tr>
			<tr>
				<th>Bail PDF</th>
				<td>
					{props.table.files.length
						? props.table.files.map((file, i) => (
								<PictoTable key={i} file={file} />
						  ))
						: ' - '}
				</td>
			</tr>
			<tr>
				<th>État des lieux d'entrée</th>
				<td>
					{props.table.inventory_entry.length
						? props.table.inventory_entry.map((file, i) => (
								<PictoTable key={i} file={file} />
						  ))
						: ' - '}
				</td>
			</tr>
			<tr>
				<th>État des lieux de sortie</th>
				<td>
					{props.table.inventory_exit.length
						? props.table.inventory_exit.map((file, i) => (
								<PictoTable key={i} file={file} />
						  ))
						: ' - '}
				</td>
			</tr>
		</tbody>
		{props.table.rent ? (
			<tbody className="reverse-color">
				<tr className="title">
					<th>Loyer</th>
					<td></td>
				</tr>
				<tr>
					<th>Loyer HT</th>
					<td>{formatNumbers(props.table.rent.value)}&nbsp;€</td>
				</tr>
				<tr>
					<th>Provisions pour charges HT</th>
					<td>
						{formatNumbers(props.table.rent.charges_provisions)}&nbsp;€
					</td>
				</tr>
				<tr>
					<th>Dépôt de garantie</th>
					<td>
						{formatNumbers(props.table.rent.security_deposits)}&nbsp;€
					</td>
				</tr>
				<tr>
					<th>Periodicité</th>
					<td>{props.table.rent.periodicity}</td>
				</tr>
			</tbody>
		) : null}
		{props.table.insurance ? (
			<tbody className="reverse-color">
				<tr className="title">
					<th>Assurance en cours</th>
					<td></td>
				</tr>
				<tr>
					<th>Date de début</th>
					<td>{props.table.insurance.start}</td>
				</tr>
				<tr>
					<th>Date de fin</th>
					<td>{props.table.insurance.end}</td>
				</tr>
				<tr>
					<th>PDF</th>
					<td>
						{props.table.insurance.files.length
							? props.table.insurance.files.map((file, i) => (
									<PictoTable
										key={i}
										file={file}
										picto_class="picto-table"
									/>
							  ))
							: ' - '}
					</td>
				</tr>
			</tbody>
		) : null}
	</table>
);

export default TableLease;
