// module
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
// component
import Notification from '../../components/Notification';
import GraphLines from '../../components/graph/GraphLines';
import { SelectorItemObject } from '../../components/form/item/SelectorItem';
import Loading from '../../components/Loading';
//actions
import { addNotifications } from '../../action_creators/notificationsActionCreators';
import {
	setBankGraph,
	setBanks,
	setCurrentBank,
} from '../../action_creators/banksActionCreators';
import { setLog } from '../../action_creators/logActionCreators';
// functions
import { createBankGraph } from '../../functions/bank';
import { getRequest, responseManagment } from '../../functions/ajax';

class DashBoard extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading_bank_graph: false,
			are_loading_notifications: false,
		};

		this.responseManagment = responseManagment.bind(this);
		this.handleAccount = this.handleAccount.bind(this);
	}

	UNSAFE_componentWillMount() {
		if (!this.props.notifications.length) {
			this.getNotifications();
		}
		if (!Object.keys(this.props.banks.graph).length) {
			this.getBankGraph();
		}
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps) {
		// Download bank graph when bank change
		if (prevProps.banks.current_bank !== this.props.banks.current_bank) {
			this.getBankGraph();
		}
	}

	async getBankGraph() {
		this.setState({ is_loading_bank_graph: true });
		const response = await getRequest(
			`/banque-graph?`
				+ `activity=${this.props.properties.current.id}`
				+ (this.props.banks.current_bank
					? `&banque=${this.props.banks.list[this.props.banks.current_bank].id}`
					: ''),
			this.props.user.token
		);
		if (this.responseManagment(response) && response.graph.years) {
			const graph = createBankGraph(response.graph);
			if (this.props.banks.list.length) {
				this.props.setBankGraph(graph);
			} else {
				this.props.setBanks(response.banks, graph);
			}
		}

		if (this._isMounted) {
			this.setState({ is_loading_bank_graph: false });
		}
	}

	async getNotifications() {
		this.setState({ are_loading_notifications: true });
		const response = await getRequest(
			`/notification?activity=${this.props.properties.current.id}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.addNotifications(response.notifications);
		}

		if (this._isMounted) {
			this.setState({ are_loading_notifications: false });
		}
	}

	handleAccount(evt) {
		this.props.setCurrentBank(evt.target.value);
	}

	render() {
		return (
			<div
				className={
					'grid grid-2 dashboard'
					+ (Object.keys(this.props.banks.graph).length
					&& this.props.banks.graph.series.length
						? ' parent-graph'
						: '')
				}
			>
				<div className="card">
					<div className="card-header">
						<h2>Notifications</h2>
					</div>
					<div className="card-body">
						{/* Starting by adding own App notifications */}
						<Notification title="Pas à pas">
							Découvrez votre accès personnalisé à votre propriété ! Il vous
							suffit de suivre le{' '}
							<a
								href={`/pdf/MYGP-Guide-Proprietaire.pdf`}
								// eslint-disable-next-line react/jsx-no-target-blank
								target="_blank"
							>
								guide
							</a>
						</Notification>
						<Notification title="Note de votre administrateur de bien">
							Bienvenue sur votre espace Propriétaire !
						</Notification>
						{/* Add API Notifications */}
						{this.state.are_loading_notifications === false ? (
							this.props.notifications.length ? (
								this.props.notifications.map((notification, i) => (
									<Notification title={notification.title} key={i}>
										{notification.description}
									</Notification>
								))
							) : null
						) : (
							<Loading />
						)}
					</div>
				</div>
				<div className="card unscrollable">
					<div className="card-header composed-card-header">
						<h2>Banque</h2>
						{this.props.banks.list.length ? (
							<SelectorItemObject
								id="select-bank-account"
								label="Compte"
								current={this.props.banks.current_bank}
								options={this.props.banks.list}
								callback={this.handleAccount}
							/>
						) : null}
					</div>
					<div className="card-body">
						{this.state.is_loading_bank_graph === false ? (
							this.props.banks.list.length ? (
								<Fragment>
									{Object.keys(this.props.banks.graph).length
									&& this.props.banks.graph.series.length ? (
										<GraphLines
											id="bank-graph"
											title="Évolution du solde de la banque saisie dans votre comptabilité sur les 12 derniers mois"
											x={this.props.banks.graph.x}
											series={this.props.banks.graph.series}
										/>
									) : (
										<p>
											Aucune donnée n'est disponible pour ce compte
											sur les 36 derniers mois.
										</p>
									)}
									<div className="btn-wrapper">
										<Link
											className="btn"
											to={`/property/${this.props.match.params.id}/dashboard/bank`}
										>
											Accéder
										</Link>
									</div>
								</Fragment>
							) : (
								<p>Aucune donnée n'est disponible sur ce compte.</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		properties: state.properties,
		user: state.user,
		banks: state.banks,
		notifications: state.notifications,
	};
};

const mapDispatchToProps = {
	addNotifications,
	setBankGraph,
	setBanks,
	setCurrentBank,
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashBoard));
