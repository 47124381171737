// module
import React from 'react';
import PropTypes from 'prop-types';
// component
import Form from './Form';
import ImageItem from './item/ImageItem';

class FormUser extends Form {
	constructor(props) {
		super(props);

		this.handleProfilePicture = this.handleProfilePicture.bind(this);
	}

	componentDidMount() {
		// Make an initialState didn't work with props
		this.setState({
			email: this.props.user.email,
			name: this.props.user.name,
			first_name: this.props.user.first_name || '',
			phone: this.props.user.phone || '',
		});
	}

	componentDidUpdate(prevProps) {
		// Should wait for user changing before updated form display values
		if (prevProps.user !== this.props.user) {
			this.setState({
				email: this.props.user.email,
				name: this.props.user.name,
				first_name: this.props.user.first_name || '',
				phone: this.props.user.phone || '',
				image: null,
			});
		}
	}

	resetInputs() {
		return;
	}

	handleProfilePicture(images) {
		this.setState({ image: images[0] });
	}

	render() {
		let form_items = this.buildFormItems();

		return (
			<form className="form" onSubmit={this.handleSubmit}>
				<div className="form-item-list">
					{form_items}
					<div className="cgu">
						<a
							href="/pdf/CGU-MonEspaceProprietaire.pdf"
							// eslint-disable-next-line react/jsx-no-target-blank
							target="_blank"
							className="fake-label"
						>
							Conditions Générales d'Utilisation
						</a>
						{this.props.user.cgu
							? ` acceptées le ${this.props.user.cgu}`
							: ' pas encore acceptées'}
					</div>
					<ImageItem
						label="Photo"
						label_btn="Modifier"
						nb_max={1}
						max_Mo={5}
						checked={this.state.image}
						callback={this.handleProfilePicture}
						setLog={log => this.props.setLog(log)}
					/>
				</div>
				<div className="submit-button-wrapper">
					<button className="btn submit-button btn-orange" type="submit">
						Valider
					</button>
				</div>
			</form>
		);
	}
}

FormUser.propTypes = {
	form_items: PropTypes.arrayOf(PropTypes.object),
	user: PropTypes.object.isRequired,
	callback: PropTypes.func.isRequired,
	setLog: PropTypes.func.isRequired,
};

export default FormUser;
