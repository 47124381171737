import * as types from '../action_types/remindersActionTypes';
// REMINDERS
export const setReminders = reminders => ({
	type: types.SET_REMINDERS,
	reminders,
});
export const setShortReminders = reminders => ({
	type: types.SET_SHORT_REMINDERS,
	reminders,
});
export const setRemindersExercices = exercices => ({
	type: types.SET_REMINDERS_EXERCICES,
	exercices,
});
export const setRemindersCurrentExercice = exercice => ({
	type: types.SET_REMINDERS_CURRENT_EXERCICE,
	exercice,
});
export const removeReminders = () => ({
	type: types.REMOVE_REMINDERS,
});
