//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
// components
import TableProcedures from '../../../components/table/TableProcedures';
import TableReminders from '../../../components/table/TableReminders';
import Loading from '../../../components/Loading';
//action
import { setShortReminders } from '../../../action_creators/remindersActionCreators';
import { setShortProcedures } from '../../../action_creators/proceduresActionCreators';
import { setLog } from '../../../action_creators/logActionCreators';
// functions
import { getRequest, responseManagment } from '../../../functions/ajax';

class MyBadNews extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			are_loading_procedures: false,
			are_loading_reminders: false,
		};

		this.responseManagment = responseManagment.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.getProcedures();
		this.getReminders();
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async getProcedures() {
		if (!this.props.procedures.shortList.length) {
			this.setState({ are_loading_procedures: true });
			const response = await getRequest(
				`/procedures-judiciaires?activity=${this.props.properties.current.id}&max=4`,
				this.props.user.token
			);
			if (this.responseManagment(response)) {
				this.props.setShortProcedures(response.procedures);
			}

			if (this._isMounted) {
				this.setState({ are_loading_procedures: false });
			}
		}
	}

	async getReminders() {
		if (!this.props.reminders.shortList.length) {
			this.setState({ are_loading_reminders: true });
			const response = await getRequest(
				`/relance?activity=${this.props.properties.current.id}`
					+ `&acquitter=${false}`
					+ `&max=4`,
				this.props.user.token
			);
			if (this.responseManagment(response)) {
				this.props.setShortReminders(response.relances);
			}

			if (this._isMounted) {
				this.setState({ are_loading_reminders: false });
			}
		}
	}

	render() {
		return (
			<div className="grid grid-2 my-bad-news">
				<div className="card">
					<div className="card-header composed-card-header">
						<h2>Mes relances</h2>
						<em>impayées</em>
					</div>
					<div className="card-body table-wrapper">
						{this.state.are_loading_reminders === false ? (
							this.props.reminders.shortList.length ? (
								<TableReminders
									cols={[
										'DATE',
										'MONTANT',
										'RESTANT',
										'TYPE',
										'DOCUMENTS',
									]}
									table={this.props.reminders.shortList}
									picto_color={this.props.properties.current.syndic}
									property={this.props.properties.current.id}
								/>
							) : null
						) : (
							<Loading />
						)}
						<div className="btn-wrapper">
							<Link
								className="btn"
								to={`/property/${this.props.match.params.id}/bad_news/reminders`}
							>
								Voir tout
							</Link>
						</div>
					</div>
				</div>
				<div className="card">
					<div className="card-header composed-card-header">
						<h2>Mes procédures judiciaires</h2>
						<em>en cours</em>
					</div>
					<div className="card-body table-wrapper">
						{this.state.are_loading_procedures === false ? (
							this.props.procedures.shortList.length ? (
								<TableProcedures
									table={this.props.procedures.shortList}
									cols={['DATE DE DÉBUT', 'LIBELLÉ', 'LOCATAIRE']}
									className="table-procedures"
									short
								/>
							) : null
						) : (
							<Loading />
						)}
						<div className="btn-wrapper">
							<Link
								className="btn"
								to={`/property/${this.props.match.params.id}/bad_news/judicial_procedures`}
							>
								Voir tout
							</Link>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		properties: state.properties,
		user: state.user,
		procedures: state.procedures,
		reminders: state.reminders,
	};
};

const mapDispacthToProps = {
	setShortProcedures,
	setShortReminders,
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(MyBadNews));
