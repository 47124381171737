// module
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
// components
import Table from '../../../components/table/Table';
import TableAdmin from '../../../components/table/TableAdmin';
import TableLeases from '../../../components/table/TableLeases';
import TableLease from '../../../components/table/TableLease';
import Modal from '../../../components/Modal';
import Loading from '../../../components/Loading';
//action
import { setLots } from '../../../action_creators/lotsActionCreators';
import {
	setLeases,
	setCurrentLease,
} from '../../../action_creators/leasesActionCreators';
import { setAdmin } from '../../../action_creators/adminActionCreators';
import { setLog } from '../../../action_creators/logActionCreators';
// functions
import {
	getRequest,
	responseManagment,
	responseBlobManagment,
} from '../../../functions/ajax';
// const
import { API_PDF_SRC_URL } from '../../../constants/api';

class MyData extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			are_loading_lots: false,
			are_loading_leases: false,
			is_loading_lease: false,
			is_loading_admin: false,
			display_lease_modal: false,
		};

		this.responseManagment = responseManagment.bind(this);
		this.getLeaseDetails = this.getLeaseDetails.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.getLots();
		this.getLeases();
		this.getAdmin();
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async getLots() {
		if (!this.props.lots.length) {
			this.setState({ are_loading_lots: true });
			const response = await getRequest(
				`/lot?activity=${this.props.properties.current.id}`,
				this.props.user.token
			);
			if (this.responseManagment(response)) {
				this.props.setLots(response.lots);
			}

			if (this._isMounted) {
				this.setState({ are_loading_lots: false });
			}
		}
	}

	async getAdmin() {
		if (!Object.keys(this.props.admin).length) {
			this.setState({ is_loading_admin: true });
			const response = await getRequest(
				`/property_admin?activity=${this.props.properties.current.id}`,
				this.props.user.token
			);
			if (this.responseManagment(response)) {
				this.props.setAdmin(response.property_admin);
			}

			if (this._isMounted) {
				this.setState({ is_loading_admin: false });
			}
		}
	}

	async getLeases() {
		if (!Object.keys(this.props.leases.list).length) {
			this.setState({ are_loading_leases: true });
			const response = await getRequest(
				`/leases?activity=${this.props.properties.current.id}`,
				this.props.user.token
			);
			if (this.responseManagment(response)) {
				this.props.setLeases(response.leases);
			}

			if (this._isMounted) {
				this.setState({ are_loading_leases: false });
			}
		}
	}

	async getLeaseDetails(id) {
		this.setState({ is_loading_lease: true });
		this.toggleModal(true);
		const response = await getRequest(
			`/leases/${id}?activity=${this.props.properties.current.id}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setCurrentLease(response.lease);
		}

		if (this._isMounted) {
			this.setState({ is_loading_lease: false });
		}
	}

	toggleModal(is_visible) {
		if (this._isMounted) {
			this.setState({
				display_lease_modal: is_visible,
			});
		}
	}

	render() {
		const leases = Object.values(this.props.leases.list);
		return (
			<div className="grid grid-3 my-data">
				<div className="card">
					<div className="card-header composed-card-header">
						<h2>
							Mes Lots
							{this.props.lots.length ? ` (${this.props.lots.length})` : ''}
						</h2>
						<em>à la date du jour</em>
					</div>
					<div className="card-body table-wrapper">
						{this.state.are_loading_lots === false ? (
							this.props.lots.length ? (
								<Fragment>
									<Table
										className="table-lots"
										table={this.props.lots.slice(0, 4)}
										keys={['reference', 'label', 'type', 'principal']}
										cols={['REF', 'LIBELLÉ', 'TYPE', 'PRINCIPAL']}
										boolean={['Non', 'Oui']}
									/>
									{this.props.lots.length > 4 && (
										<div className="btn-wrapper">
											<Link
												className="btn"
												to={`/property/${this.props.match.params.id}/data/lots`}
											>
												Voir tout
											</Link>
										</div>
									)}
								</Fragment>
							) : (
								<p>Vous ne possédez pas de lots.</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
				<div className="card">
					<div className="card-header composed-card-header">
						<h2>Administrateur de bien</h2>
						<em>en cours</em>
					</div>
					<div className="card-body table-wrapper">
						{this.state.is_loading_admin === false ? (
							Object.keys(this.props.admin).length ? (
								<TableAdmin table={this.props.admin} />
							) : (
								<p>Il n'y a pas d'administrateur de bien sur ce bien.</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
				<div className="card">
					<div className="card-header">
						<h2>Mes Baux</h2>
					</div>
					<div className="card-body table-wrapper">
						{this.state.are_loading_leases === false ? (
							leases.length ? (
								<Fragment>
									<TableLeases
										cols={[
											'LIBELLÉ',
											'LOCATAIRE',
											'ÉCHÉANCE BAIL',
											'PROCHAIN LOYER',
											'DÉTAILS',
										]}
										table={leases.slice(0, 4)}
										displayLease={this.getLeaseDetails}
									/>
									{leases.length > 4 && (
										<div className="btn-wrapper">
											<Link
												className="btn"
												to={`/property/${this.props.match.params.id}/data/leases`}
											>
												Voir tout
											</Link>
										</div>
									)}
								</Fragment>
							) : (
								<p>Aucun bail disponible sur cette propriété.</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
				<Modal
					id="lease-modal"
					is_visible={this.state.display_lease_modal}
					callback={this.toggleModal}
				>
					{this.state.is_loading_lease === false ? (
						<Fragment>
							<div className="modal-header">
								<h2>
									Bail{' '}
									{this.props.leases.current
										? this.props.leases.current.label
										: null}
								</h2>
							</div>
							{this.props.leases.current ? (
								<TableLease table={this.props.leases.current} />
							) : (
								<p>
									Il n'y a aucune donnée supplémentaires pour ce bail.
								</p>
							)}
						</Fragment>
					) : (
						<Loading />
					)}
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		properties: state.properties,
		user: state.user,
		lots: state.lots,
		leases: state.leases,
		admin: state.admin,
	};
};
const mapDispacthToProps = {
	setLots,
	setAdmin,
	setLeases,
	setCurrentLease,
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(MyData));
