// modules
import jsPDF from 'jspdf';
import 'jspdf-autotable';
// constants
import * as colors from '../constants/colors';

/**
 * Convert html to pdf
 * @param {string} target
 * @param {string} filename
 */
export function printTable(target, filename, title, property, autoFilter) {
	const date = new Date().toLocaleDateString('fr-FR');
	const filtre = autoFilter ? autoFilter() : getSelectFilters();
	const doc = new jsPDF();
	const minCellWidth = selectMinCellWidth(target);
	doc.autoTable({
		html: `.${target}`,
		theme: 'striped',
		useCss: true,
		styles: {
			textColor: colors.grey,
			fontSize: 8,
			valign: 'middle',
			minCellWidth,
		},
		headStyles: {
			fontStyle: 'bold',
			textColor: colors.blue,
			fillColor: colors.light_blue,
		},
		margin: 6,
		startY: filtre ? 14.5 : 9,
		didParseCell: data => stylefromClass(data),
		didDrawPage: data => {
			doc.setTextColor(colors.grey);
			if (data.pageNumber === 1) {
				doc.setFontSize(10);
				doc.text(`${title} - ${property.name} - ${date}`, 8, 6);
				if (filtre) {
					doc.setFontSize(8);
					doc.text(filtre, 8, 11.2);
				}
			}
			doc.setFontSize(6);
			doc.text('www.monespaceproprietaire.com', 8, 293.9); // A4=210x297
			doc.setFontSize(8);
			doc.text(`${data.pageNumber}`, data.cursor.x / 2, 294);
		},
	});
	doc.save(filename);
}

function selectMinCellWidth(target) {
	switch (target) {
		case 'table-charges-state':
		case 'table-exercice-state':
		case 'table-tenant-charges-state':
			return 28;
		case 'table-bank':
			return 25;
		default:
			return 22;
	}
}
function stylefromClass(data) {
	const style = data.cell.styles;
	style.fontSize = 8;
	style.cellPadding = 1.5;
	const html_row = data.row.raw;
	// eslint-disable-next-line default-case
	switch (html_row.className) {
		case 'title':
		case 'subtitle':
			style.fontStyle = 'bold';
			break;
		case 'total':
			style.fontStyle = 'bold';
			break;
		case 'solde':
		case 'subtotal':
		case 'subsubtotal':
			style.fillColor = colors.light_blue;
			style.fontStyle = 'bold';
			break;
		case 'tr-colored':
			style.fillColor = colors.pale_blue;
			break;
	}
}

export function getSelectFilters() {
	let selects = document.getElementsByTagName('select');
	let text = '';
	// select is a complex javascript object so we use classic for in place of forEach
	for (let i = 0; i < selects.length; i++) {
		const current_select = selects[i];
		const label = document.querySelector(
			`label[for=${current_select.id}]`
		).textContent;
		const option = current_select[current_select.value].textContent;
		text += `${label} ${option}`;
		if (i < selects.length - 1) {
			text += ' | ';
		}
	}
	return text;
}
