import * as types from '../action_types/crgActionTypes';

const initialState = [];

function crgReducer(state = initialState, action) {
	let nextState;

	switch (action.type) {
		case types.SET_CRG:
			nextState = action.crg;
			return nextState || state;

		case types.REMOVE_CRG:
			return initialState;

		default:
			return state;
	}
}

export default crgReducer;
