import * as types from '../action_types/tenantsActionTypes';

// Tenants
export const setTenants = (tenants, current_page) => ({
	type: types.SET_TENANTS,
	tenants,
	current_page
});
export const setNbPagesTenants = (nb) => ({
	type: types.SET_NB_PAGES_TENANTS,
	nb
});
export const setSoldeTenants = (solde, solde_treasury) => ({
	type: types.SET_SOLDE_TENANTS,
	solde,
	solde_treasury
});
export const setTenantsExercice = (exercice) => ({
	type: types.SET_TENANTS_EXERCICE,
	exercice
});
export const removeTenants = () => ({
	type: types.REMOVE_TENANTS
});