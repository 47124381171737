import * as types from '../action_types/propertiesActionTypes';

const initialState = {
	list: {},
	current: {},
};

function propertiesReducer(state = initialState, action) {
	let nextState;

	switch (action.type) {
		case types.SET_PROPERTIES:
			nextState = {
				...state,
				list: action.properties,
			};
			return nextState || state;

		case types.SET_CURRENT_PROPERTY:
			nextState = {
				...state,
				current: action.property,
			};
			return nextState || state;

		case types.REMOVE_CURRENT_PROPERTY:
			nextState = {
				...state,
				current: initialState.current,
			};
			return nextState || state;

		case types.REMOVE_PROPERTIES:
			return initialState;

		default:
			return state;
	}
}

export default propertiesReducer;
