import * as types from '../action_types/profitActionTypes';

const initialState = {
	amounts: {},
	detail: {},
	exercices: [],
	lots: {},
	current_exercice: 0,
	current_lot: 0,
	history: {
		global: { exercice: 0 },
		detail: { exercice: 0 },
	},
};

function profitReducer(state = initialState, action) {
	let nextState;

	switch (action.type) {
		case types.SET_GLOBAL_PROFITS:
			nextState = {
				...state,
				amounts: action.amounts,
				history: {
					...state.history,
					global: { exercice: state.current_exercice },
				},
			};
			return nextState || state;

		case types.SET_PROFIT_BY_LOT:
			nextState = {
				...state,
				detail: action.profit,
				history: {
					...state.history,
					detail: {
						exercice: state.current_exercice,
					},
				},
			};
			return nextState || state;

		case types.SET_PROFIT_EXERCICES:
			nextState = {
				...state,
				exercices: action.exercices,
			};
			return nextState || state;

		case types.SET_PROFIT_CURRENT_EXERCICE:
			nextState = {
				...state,
				current_exercice: action.exercice,
			};
			return nextState || state;

		case types.SET_PROFIT_LOTS:
			nextState = {
				...state,
				lots: action.lots,
			};
			return nextState || state;

		case types.SET_PROFIT_CURRENT_LOT:
			nextState = {
				...state,
				current_lot: action.lot,
			};
			return nextState || state;

		case types.REMOVE_PROFIT:
			return initialState;

		default:
			return state;
	}
}

export default profitReducer;
