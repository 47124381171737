import * as types from '../action_types/honorariesActionTypes';

const initialState = {
    short_list: [],
    count: 0,
	list: [],
	exercices: [],
	current_exercice: 0,
};

function honorariesReducer(state = initialState, action) {
	let nextState;

	switch (action.type) {
		case types.SET_HONORARIES:
			nextState = {
				...state,
				list: action.honoraries,
			};
			return nextState || state;

		case types.SET_HONORARIES_SHORT:
			nextState = {
				...state,
                short_list: action.honoraries,
                count: action.count
			};
			return nextState || state;

		case types.SET_HONORARIES_EXERCICES:
			nextState = {
				...state,
				exercices: action.exercices || [],
			};
			return nextState || state;

		case types.SET_HONORARIES_CURRENT_EXERCICE:
			nextState = {
				...state,
				current_exercice: action.exercice,
			};
			return nextState || state;

		case types.REMOVE_HONORARIES:
			return initialState;

		default:
			return state;
	}
}

export default honorariesReducer;
