import * as types from '../action_types/proceduresActionTypes';

const initialState = {
	list: [],
	shortList: [],
};

function proceduresReducer(state = initialState, action) {
	let nextState;

	switch (action.type) {
		case types.SET_PROCEDURES:
			nextState = {
				...state,
				list: action.procedures,
			};
			return nextState || state;

		case types.SET_SHORT_PROCEDURES:
			nextState = {
				...state,
				shortList: action.procedures,
			};
			return nextState || state;

		case types.REMOVE_PROCEDURES:
			return initialState;

		default:
			return state;
	}
}

export default proceduresReducer;
