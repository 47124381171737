
// functions
import { getRequest } from './ajax';
// constants
import { STORED_PROPERTIES } from '../constants/localStorage';

/**
 * Get properties
 * @param {string} token
 * @return {object}
 * Needs to be bind
 */
export async function getProperties(token) {
    const response = await getRequest(`/activities`, token);
    if (this.responseManagment(response)) {
        if (Object.keys(response.activities).length) {
            localStorage.setItem(
                STORED_PROPERTIES,
                JSON.stringify(response.activities)
            );
            this.props.setProperties(response.activities);
            return response.activities;
        } else {
            this.props.setLog({
               type: "success",
                message: "Vous n'avez pas ou plus de propriétés en votre possession.",
            });
        }
    }
    return [];
}