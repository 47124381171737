//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import PrintButton from '../../../components/button/PrintButton';
import TableProcedures from '../../../components/table/TableProcedures';
import Loading from '../../../components/Loading';
// action
import { setLog } from '../../../action_creators/logActionCreators';
import { setProcedures } from '../../../action_creators/proceduresActionCreators';
// functions
import { getRequest, responseManagment } from '../../../functions/ajax';
import { printTable } from '../../../functions/print';

class MyJudicialProcedures extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
		};

		this.responseManagment = responseManagment.bind(this);
		this.handlePrint = this.handlePrint.bind(this);
		this.goBack = this.goBack.bind(this);
	}

	UNSAFE_componentWillMount() {
		if (!this.props.procedures.list.length) {
			this.getJudicialProcedures();
		}
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async getJudicialProcedures() {
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/procedures-judiciaires?activity=${this.props.properties.current.id}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setProcedures(response.procedures);
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	handlePrint(evt) {
		printTable(
			'table-judicial-procedures',
			'procedure-judiciaires-propriete.pdf',
			'Procédure judiciaire en cours',
			this.props.properties.current
		);
	}

	goBack(evt) {
		this.props.history.push(`/property/${this.props.match.params.id}/bad_news/`);
	}

	render() {
		return (
			<div className="grid grid-1">
				<div className="card">
					<div className="card-header card-header-with-back">
						<button className="back-btn" onClick={this.goBack}>
							Revenir en arrière
						</button>
						<h2>Procédure judiciaire</h2>
						<em>en cours</em>
						<PrintButton
							picto_color={this.props.properties.current.syndic}
							print={this.handlePrint}
						/>
					</div>
					<div className="card-body table-wrapper">
						{this.state.is_loading === false ? (
							this.props.procedures.list.length ? (
								<TableProcedures
									table={this.props.procedures.list}
									cols={[
										'DATE DE DÉBUT',
										'LIBELLÉ',
										'LOCATAIRE',
										'ÉTAPES',
									]}
								/>
							) : (
								<p>Il n'y a pas de procédure en cours.</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		properties: state.properties,
		user: state.user,
		procedures: state.procedures,
	};
};
const mapDispatchToProps = {
	setLog,
	setProcedures,
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(MyJudicialProcedures)
);
