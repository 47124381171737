import * as types from '../action_types/honorariesActionTypes';

export const setHonoraries = (honoraries) => ({
	type: types.SET_HONORARIES,
	honoraries
});
export const setHonorariesShort = (honoraries, count) => ({
	type: types.SET_HONORARIES_SHORT,
	honoraries,
	count
});
export const setHonorariesExercices = (exercices) => ({
	type: types.SET_HONORARIES_EXERCICES,
	exercices
});
export const setHonorariesCurrentExercice = (exercice) => ({
	type: types.SET_HONORARIES_CURRENT_EXERCICE,
	exercice
});
export const removeHonoraries = () => ({
	type: types.REMOVE_HONORARIES
});